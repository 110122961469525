import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Copy, ButtonLink } from '../page/components'

export default ({ isAccount }) => {
  return (
    <Flex flexWrap="nowrap">
      <Box width="100%">
        <Copy>
          <h3>
            Your free student membership to The Guide has been extended by 4
            weeks!
          </h3>
          <p>
            Now that you’ve graduated, you can keep referencing the amazing
            content created and curated by Shillington’s passionate teachers, at
            your fingertips. These resources are here to help you throughout
            your creative career—from the job hunt to technical help.
          </p>
          <p>For the next 4 weeks*, your Shillumni Membership includes: </p>
          <ul>
            <li>
              Free access to The Guide—packed with industry insights to help you
              set goals and start your career.
            </li>
            <li>
              All the content from the Shillington course to refer back to.
            </li>
            <li>
              Relevant content that constantly evolves with the design industry
              to ensure The Guide remains as up-to-date as possible.
            </li>
            <li>
              Fresh design inspiration, case studies and industry examples.
            </li>
            <li>
              Searchable, portable, animated content created to support our
              graduates.
            </li>
          </ul>
          <p>
            <i>
              *After 4 weeks, you can sign up for a paid subscription—all for
              just $10 USD/$12 AUD/£10 a month.
            </i>
          </p>
          <ButtonLink>
            <a href="/graphic-design">Continue</a>
          </ButtonLink>
        </Copy>
      </Box>
    </Flex>
  )
}
